import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLogout } from '../../services/api/auth';
import { useAuthStore } from '../../services/stores/useAuthStore';
import { NavigationItem } from './NavigationItem';

export const NavigationDrawer: FC = () => {
  const { pathname } = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();
  const { clear } = useAuthStore();

  const handleLogout = async () => {
    try {
      await logout.mutateAsync();
      clear();
      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <StyledNavigationDrawer>
      <StyledNavigationHeader>
        <img src="/favicon.ico" alt="Logo" />
        <h2>Arkivet</h2>
      </StyledNavigationHeader>
      {ITEMS.map(({ text, href }) => (
        <NavigationItem isActive={pathname === href} key={href} text={text} href={href} />
      ))}
      <NavigationItem isActive={false} text="Log ut" onClick={handleLogout} />
    </StyledNavigationDrawer>
  );
};
const StyledNavigationDrawer = styled.div`
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  button:nth-last-child(2) {
    margin-bottom: auto;
  }
  * {
    width: 100%;
  }
`;

const StyledNavigationHeader = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  img {
    margin-top: 10px;
    width: 50px;
    height: 50px;
  }
`;

const ITEMS = [
  {
    text: 'Produkter',
    href: '/products',
  },
  {
    text: 'Prosjekter',
    href: '/projects',
  },
];
