import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { fonts } from './Fonts';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${fonts}
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  html {
    box-sizing: border-box;
    font-size: 100%;
    overflow-y: scroll;
  }

  body {
    font-family: SpaceGroteskRegular;
  }

  h1, h2, a, b {
    font-family: SpaceGroteskRegular;
  }
`;
