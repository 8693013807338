import React, { FC } from 'react';
import styled from 'styled-components';

export const Project: FC = () => {
  return (
    <StyledProject>
      <p>Dette er prosjekter</p>
    </StyledProject>
  );
};

const StyledProject = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
