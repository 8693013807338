import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.interactive};
  text-decoration: none;
  color: #ffffff;
  padding: 8px 24px;
  border-radius: 4px;
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  border: none;
  :disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }
`;
