import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { UserType } from '../stores/useAuthStore';
import { arkivetApi } from './axios';

type LoginType = {
  email: string;
  password: string;
};

export const useLogin = () => {
  return useMutation<{ success: boolean }, AxiosError, LoginType, unknown>(async (values: LoginType) => {
    const res = await arkivetApi({
      method: 'POST',
      url: '/v1/auth/login/uspw',
      withCredentials: true,
      data: {
        ...values,
      },
    });
    return res.data;
  });
};

type SignupType = {
  roles: ['designer' | 'admin' | 'customer'];
  email: string;
  password: string;
};

export const useSignUp = () => {
  return useMutation<{ success: boolean }, AxiosError, SignupType, unknown>(async (values: SignupType) => {
    const res = await arkivetApi({
      method: 'POST',
      url: '/v1/auth/signup/uspw',
      data: {
        ...values,
      },
    });
    return res.data;
  });
};

export const useLogout = () => {
  return useMutation<{ success: boolean }, AxiosError>(async () => {
    const res = await arkivetApi({
      method: 'GET',
      url: '/v1/auth/logout',
      withCredentials: true,
    });
    return res.data;
  });
};

export const useSession = () => {
  return useQuery<UserType, AxiosError>(
    'session',
    async () => {
      const res = await arkivetApi({
        method: 'GET',
        url: '/v1/auth/session',
        withCredentials: true,
      });
      console.log('res', res);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
