import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../components/styled/StyledButton';
import { StyledInput } from '../components/styled/StyledInput';
import { useSignUp } from '../services/api/auth';

export const SignUp: FC = () => {
  const singUp = useSignUp();
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const handleSignUp = async () => {
    if (emailRef.current?.value) {
      try {
        await singUp.mutateAsync({
          email: emailRef.current?.value,
          password: passwordRef.current!.value,
          roles: ['designer'],
        });
      } catch (e) {
        console.log('e', e);
      }
    }
  };

  return (
    <StyledSignUp>
      <StyledSignUpForm>
        <StyledInput type="email" placeholder="Email" ref={emailRef} />
        <StyledInput type="password" placeholder="Password" ref={passwordRef} />
        <StyledButton onClick={handleSignUp}>Send</StyledButton>
      </StyledSignUpForm>
    </StyledSignUp>
  );
};

const StyledSignUp = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSignUpForm = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  button {
    align-self: flex-end;
  }
`;
