import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 10px 20px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 16px;
  outline: 1px solid lightgray;
  border: none;
`;
