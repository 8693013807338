import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLogin } from '../services/api/auth';

export const Login: FC = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const login = useLogin();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (emailRef.current) {
      try {
        await login.mutateAsync({
          email: emailRef.current.value,
          password: passwordRef.current!.value,
        });
        navigate('/products');
      } catch (err: any) {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <StyledLogin>
      <StyledLoginForm>
        <input type="email" placeholder="Email" ref={emailRef} />
        <input type="password" placeholder="Passord" ref={passwordRef} />
        <button onClick={handleLogin}>Login</button>
        {errorMessage && (
          <p
            style={{
              color: 'red',
            }}
          >
            {errorMessage}
          </p>
        )}
      </StyledLoginForm>
    </StyledLogin>
  );
};

const StyledLogin = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoginForm = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
