import React, { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { NavigationDrawer } from './components/navigation/NavigationDrawer';
import { Login } from './pages/Login';
import { Product } from './pages/Products';
import { Project } from './pages/Projects';
import { SignUp } from './pages/SignUp';
import { useSession } from './services/api/auth';
import { useAuthStore, UserType } from './services/stores/useAuthStore';
import { GlobalStyle } from './styles/GlobalStyle';
import { ArkivetThemeProvider, useTheme } from './theme/useTheme';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ArkivetThemeProvider>
        <Session />
      </ArkivetThemeProvider>
    </QueryClientProvider>
  );
};

const Session = () => {
  const session = useSession();

  return <InnerApp session={session.data} />;
};

const InnerApp = ({ session }: { session?: UserType }) => {
  const { theme } = useTheme();
  const { isLoggedIn, setIsLoggedIn, setUser } = useAuthStore();

  useEffect(() => {
    if (session) {
      setUser(session);
    }
  }, [session, setIsLoggedIn, setUser]);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledAppContainer>
        {isLoggedIn && <NavigationDrawer />}

        <Routes>
          <Route path="/" element={<Navigate replace to={!isLoggedIn ? '/login' : '/products'} />} />
          {isLoggedIn ? (
            <>
              <Route path="/products" element={<Product />} />
              <Route path="/projects" element={<Project />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />
            </>
          )}
        </Routes>
      </StyledAppContainer>
    </ThemeProvider>
  );
};

const StyledAppContainer = styled.div`
  /* min-height: 100vh; */
  display: grid;
  grid-template-columns: 300px 1fr;
`;
